



































































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { useBank, useBlob } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DATE_TIME_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import {
  RequestParamsCashBankStatement,
  ResponseCashBankStatementReportDTO,
  ResponseCashBankStatementReportLineDTO,
} from "@/models/interface/cashManagement.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormValues = {
  bankId: string;
  transactionDate: Moment[];
};

type Row = { key: number; no: number } & ResponseCashBankStatementReportLineDTO;

@Component
export default class BankStatementV2 extends Mixins(MNotificationVue) {
  @Ref("reportForm") reportForm!: FormModel;

  moment = moment;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  formModel: FormValues = {
    bankId: "",
    transactionDate: [],
  };

  formRules = {
    bankId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    transactionDate: [
      {
        required: true,
        type: "array",
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  columns = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
    },
    {
      title: this.$t("lbl_transaction_number"),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: this.$t("lbl_customer_supplier_code"),
      dataIndex: "contactsCode",
      key: "contactsCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_supplier_name"),
      dataIndex: "contactsName",
      key: "contactsName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_debet"),
      dataIndex: "debit",
      key: "debit",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_credit"),
      dataIndex: "credit",
      key: "credit",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      key: "balance",
      scopedSlots: { customRender: "currency" },
    },
  ];

  detailCashBankStatements: ResponseCashBankStatementReportDTO = {
    beginningBalance: 0,
    endingBalance: 0,
    statementReportLineDTOList: [],
  };

  loading = {
    bank: false,
    download: false,
    print: false,
    find: false,
  };

  optBanks: Option[] = [];

  created(): void {
    this.fetchBank();
  }

  get dataSource(): Row[] {
    return this.detailCashBankStatements.statementReportLineDTOList.map<Row>(
      (item, i) => ({
        key: i,
        no: i + 1,
        ...item,
      })
    );
  }

  onSearchBank(value = ""): void {
    let q = "";
    if (value) {
      q = new SearchBuilder()
        .push(["bankAccName", value], { like: "both" })
        .or()
        .push(["bankAccNumber", value], { like: "both" })
        .or()
        .push(["bankName", value], { like: "both" })
        .build();
    }
    debounce(() => this.fetchBank({ search: q }));
  }

  fetchBank(p?: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useBank();
    const params = new RequestQueryParams(
      p?.search,
      p?.page,
      p?.sorts,
      p?.limit
    );
    this.loading.bank = true;
    findAll(params)
      .then(response => {
        this.optBanks = toOptions(response.data);
      })
      .finally(() => {
        this.loading.bank = false;
      });
  }

  handleReset(): void {
    this.reportForm.resetFields();
  }

  validateForm(source: "download" | "print" | "submit"): void {
    this.reportForm.validate((valid: boolean) => {
      if (!valid) return;
      if (source === "download") this.download(this.formModel);
      else if (source === "print") this.print(this.formModel);
      else this.preFind();
    });
  }

  preFind(): void {
    const params: RequestParamsCashBankStatement = {
      bankId: this.formModel.bankId,
      dateFrom: this.formModel.transactionDate[0]
        .set({ hour: 0, minute: 0, second: 0 })
        .format(DATE_TIME_DEFAULT_FORMAT),
      dateTo: this.formModel.transactionDate[1]
        .set({ hour: 23, minute: 59, second: 59 })
        .format(DATE_TIME_DEFAULT_FORMAT),
    };
    this.findData(params);
  }

  findData(params: RequestParamsCashBankStatement): void {
    this.loading.find = true;
    cashManagementServices
      .getAllCashBankStatement(params)
      .then(response => {
        this.detailCashBankStatements.statementReportLineDTOList =
          response.statementReportLineDTOList;
        this.detailCashBankStatements.beginningBalance =
          response.beginningBalance;
        this.detailCashBankStatements.endingBalance = response.endingBalance;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  async buildParams(
    field: FormValues
  ): Promise<RequestQueryParamsModel | null> {
    try {
      const filter: string[] = [
        this.$store.state.authStore.authData.companyName,
      ];
      const { findAll } = useBank();
      const response = await findAll({ search: "secureId~" + field.bankId });
      const [bank] = response.data;
      filter.push(bank.bankAccName);
      filter.push(bank.bankAccNumber);

      const [start, end] = field.transactionDate;
      filter.push(
        start
          .set({ hour: 0, minute: 0, second: 0 })
          .format(DATE_TIME_DEFAULT_FORMAT)
      );
      filter.push(
        end
          .set({ hour: 23, minute: 59, second: 59 })
          .format(DATE_TIME_DEFAULT_FORMAT)
      );
      const params: RequestQueryParamsModel = {
        bankId: field.bankId,
        dateFrom: field.transactionDate[0]
          .set({ hour: 0, minute: 0, second: 0 })
          .format(DATE_TIME_DEFAULT_FORMAT),
        dateTo: moment(field.transactionDate[1])
          .set({ hour: 23, minute: 59, second: 59 })
          .format(DATE_TIME_DEFAULT_FORMAT),

        params: filter.join(","),
      };
      return params;
    } catch (error) {
      this.showNotifError("notif_download_error");
      return null;
    }
  }

  async download(field: FormValues): Promise<void> {
    try {
      const params = await this.buildParams(field);
      if (!params) return;
      this.loading.download = true;
      const response = await cashManagementServices.downloadReportCashFlow(
        params
      );
      const { toDownload } = useBlob();
      toDownload(response, "report_bank_statement.xlsx");
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  async print(field: FormValues): Promise<void> {
    try {
      const params = await this.buildParams(field);
      if (!params) return;
      this.loading.print = true;
      const response = await cashManagementServices.printReportCashFlow(params);
      const url = window.URL.createObjectURL(new Blob([response]));
      printJS(url);
    } catch (error) {
      this.showNotifError("notif_print_fail");
    } finally {
      this.loading.print = false;
    }
  }
}
